export const TEXT_VARIANTS = Object.freeze({
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  PARAGRAPH: 'paragraph',
  PARAGRAPH_MEDIUM: 'paragraph-medium',
  PARAGRAPH_SEMIBOLD: 'paragraph-semibold',
  PARAGRAPH_BOLD: 'paragraph-bold',
  LINK: 'link',
  CONTAINER: 'container',
  BULLET: 'bullet'
});

export const TEXT_STATUS = Object.freeze({ INFO: 'info', ERROR: 'error', SUCCESS: 'success' });
